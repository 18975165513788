.ChatHistoryNav-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: var(--white-pure);
  transition: all 0.5s ease-in-out;

  &.isOpen {
    width: 315px;
  }

  .ChatHistoryNav {
    padding: 24px;


    .ChatHistoryNav-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-shrink: 0;

      svg {
        width: 20px;
        height: 20px;
        fill: var(--text-color-primary);
      }

      .CloseHistoryIcon {
        svg {
          width: 18px;
          height: 18px;
          fill: var(--text-color-primary);
        }
      }
    }

    .ChatHistoryNav-body {
      overflow-y: auto;
      flex-grow: 1;

      .ChatHistorySection {
        @extend .mb-4;

        label {
          color: var(--text-color-secondary);
          font-size: 13px;
          font-weight: 400;
          margin: 0;
        }

        .ChatHistoryItem {
          @extend .truncate-text-at-1-lines, .pointer;

          font-size: 14px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }
}


.ChatHistoryButton {
  @extend .bordered;

  position: absolute;
  bottom: 28px;
  left: 28px;

  padding: 12px;
  background-color: transparent;
  color: var(--text-color-primary);

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}
