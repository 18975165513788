.AgentsSection {
  .AgentsGrid {
    @extend .bordered;

    padding: 20px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-column-gap: 60px;
    grid-row-gap: 1rem;
  }
}
